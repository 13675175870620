<script lang="ts" context="module">
    const GT_PREFIX = "grouptube://"
    const GT_PREFIX_LENGTH = GT_PREFIX.length

    export function imagePath(path: string, size: 128 | 256 | 512 | 1024) {
        return `${path.substring(GT_PREFIX_LENGTH)}-${size}w`
    }
</script>

<script lang="ts">
    let className = ""
    export { className as class }

    /* ========================================================================================== */

    export let image: string
    export let size: 128 | 256 | 512 = 256
    $: sizeDouble = (size * 2) as 256 | 512 | 1024

    export let alt = ""
    export let loading: "eager" | "lazy" | null = null
</script>

{#if image.startsWith(GT_PREFIX)}
    <picture class="overflow-hidden {className}">
        <source
            type="image/avif"
            srcset="{imagePath(image, size)}.avif, {imagePath(image, sizeDouble)}.avif 2x"
        />
        <source
            type="image/webp"
            srcset="{imagePath(image, size)}.webp, {imagePath(image, sizeDouble)}.webp 2x"
        />
        <source
            type="image/jpeg"
            srcset="{imagePath(image, size)}.jpeg, {imagePath(image, sizeDouble)}.jpeg 2x"
        />
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <img
            src="{imagePath(image, size)}.jpeg"
            {alt}
            on:click
            class="w-full"
            draggable={false}
            {loading}
        />
    </picture>
{:else}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
    <img src={image} {alt} on:click class={className} draggable={false} {loading} />
{/if}
