import type { GroupMemberProfile } from "$bindings/api/GroupMemberProfile"
import type { GroupMemberType } from "$bindings/api/GroupMemberType"
import type { State } from "$bindings/group/State"
import type { UserId } from "$bindings/group/UserId"
import { imagePath } from "$components/Image.svelte"
import * as env from "$env/static/public"
import { parse, toSeconds } from "iso8601-duration"
import millify from "millify"
import { format as timeago } from "timeago.js"
import type { AccountId, ClientId } from "./group/Group"
import type { UserData } from "./model/IUser"
import type { UserStore } from "./model/Users"
import { USER_ID_UNKNOWN } from "./substitute"

export function resolveGroupTubeImageUrl(url: string, size: 128 | 256 | 512 | 1024 = 256): string {
    if (url.startsWith("grouptube://")) {
        return `${env.PUBLIC_FRONTEND_URL}${imagePath(url, size)}.jpeg`
    } else {
        return url
    }
}

export function hashUserInfo(userId: UserData): string {
    if (userId.type === "Account") {
        return userId.type + "/" + userId.id
    } else {
        return userId.type + "/" + userId.name
    }
}

export function hashUserId(userId: UserId): string {
    return userId.type + "/" + userId.content
}

/** Wraps a function and caches its input-output mapping. */
export function memoize<I, O>(func: (args: I) => O): (args: I) => O {
    // a cache of results
    const results = new Map<string, O>()
    // return a function for the cache of results
    return (...args) => {
        // a JSON key to save the results cache
        const argsKey = JSON.stringify(args)
        // execute `func` only if there is no cached value of clumsysquare()

        if (!results.has(argsKey)) {
            // store the return value of clumsysquare()
            results.set(argsKey, func(...args))
        }
        // return the cached results
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return results.get(argsKey)!
    }
}

/** Formats a given number to a human-friendly format.
 *
 *  ## Examples:
 *
 *  5 -> "5"
 *
 *  1000 -> "1K"
 *
 *  21765316 -> "21M"
 */
export function numberToHumanString(num: number): string {
    return millify(num, { units: ["", "K", "M", "B", "T"] })
}

export function bytesToHumanString(num: number): string {
    return millify(num, { units: ["B", "KB", "MB", "GB", "TB"], space: true })
}

/** Formats a given timestamp to a human-friendly format.
 *
 *  ## Examples:
 *
 *  "2022-05-15T14:59:18Z" -> "5 days ago"
 */
// TODO: Fix this... currenly displays HH:mm when less than 24h ago...
// TODO: Make it reactive (return Observable)
export function timestampToAgoString(timestamp: string | number): string {
    const date = new Date(timestamp)
    if (Date.now() - date.getTime() > 1000 * 60 * 60 * 24) {
        return timeago(date)
    } else {
        return date.toLocaleTimeString([], { timeStyle: "short" })
    }
}

export async function sleep(ms: number) {
    await new Promise<void>((resolve) =>
        setTimeout(() => {
            resolve()
        }, ms),
    )
}

export function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj))
}

export function secsToHumanString(seconds: number): string {
    const hours = Math.floor(seconds / 60 / 60)
    const mins = Math.floor((seconds - hours * 60 * 60) / 60)
    const secs = Math.floor(seconds % 60)
    if (hours > 0) {
        return `${hours}:${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`
    } else {
        return `${mins}:${String(secs).padStart(2, "0")}`
    }
}

export function youtubeDurationToHumanString(duration: string): string {
    const secs = toSeconds(parse(duration))
    return secsToHumanString(secs)
}

export function stateIsPlaying(state: State | null): boolean {
    if (state && state.variant.content.play_state.type === "Play") return true
    return false
}

/**
 * Calculates what time the media is at, at `currentTime`.
 * - `Pause` and `Ended` simple return the time
 * - `Play` returns the time shifted by the time passed
 * - `Live` returns a huge number
 */
export function currentStateTime(state: State, currentTime: number): number {
    const stateSpeed = state.variant.content.speed
    const play_state = state.variant.content.play_state
    const stateTime =
        play_state.type === "Play"
            ? ((currentTime - state.time) / 1000) * stateSpeed + play_state.content
            : play_state.type === "Live"
              ? 9_999_999_999
              : play_state.content
    return stateTime
}

// export function clamp(value: number, max: number | null): number {
//     return max !== null ? Math.min(value, max) : value
// }
export function clamp(value: number, min: number | null, max: number | null): number {
    value = max !== null ? Math.min(value, max) : value
    value = min !== null ? Math.max(value, min) : value
    return value
}

export function average(numbers: number[]): number {
    const sum = numbers.reduce((acc, val) => acc + val, 0)
    return sum / numbers.length
}

function groupOrderValue(type: GroupMemberType): number {
    switch (type) {
        case "Admin":
            return 3
        case "Member":
            return 2
        case "Guest":
            return 1
    }
}

// TODO: The follwing sorting function should/could be part of `group.utils.sort/order.*`, then we would not have to use a constructor function

export function orderAccountId(userStore: UserStore, members: Map<AccountId, GroupMemberProfile>) {
    return function (a_aid: AccountId, b_aid: AccountId) {
        const user_a = userStore.get({ type: "Account", content: a_aid })
        const user_b = userStore.get({ type: "Account", content: b_aid })

        // Sort by group
        const group_a = groupOrderValue(members.get(a_aid)?.type ?? "Guest")
        const group_b = groupOrderValue(members.get(b_aid)?.type ?? "Guest")
        if (group_a < group_b) {
            return 1
        } else if (group_a > group_b) {
            return -1
        }

        // Sort by name
        if (user_a.name < user_b.name) {
            return -1
        } else if (user_a.name > user_b.name) {
            return 1
        } else {
            return 0
        }
    }
}

export function orderClientIdAny(
    users: Map<ClientId, UserId>,
    userStore: UserStore,
    members: Map<AccountId, GroupMemberProfile>,
    you_cid: ClientId | null,
) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return function ([a_cid, _a_any]: [ClientId, unknown], [b_cid, _b_any]: [ClientId, unknown]) {
        const a_uid = users.get(a_cid) ?? USER_ID_UNKNOWN
        const b_uid = users.get(b_cid) ?? USER_ID_UNKNOWN

        const user_a = userStore.get(a_uid)
        const user_b = userStore.get(b_uid)

        // Sort by you
        if (a_cid === you_cid) {
            return -1
        } else if (b_cid === you_cid) {
            return 1
        }

        // Sort by group
        const group_a = groupOrderValue(members.get(a_uid.content)?.type ?? "Guest")
        const group_b = groupOrderValue(members.get(b_uid.content)?.type ?? "Guest")
        if (group_a < group_b) {
            return 1
        } else if (group_a > group_b) {
            return -1
        }

        // Sort by name
        if (user_a.name < user_b.name) {
            return -1
        } else if (user_a.name > user_b.name) {
            return 1
        } else {
            return 0
        }
    }
}

/** Zips two arrays together, resulting array has the length of the first array. */
export function zip<A, B>(a: A[], b: B[]): [A, B][] {
    return a.map((k, i) => [k, b[i]])
}

export function youtubeUrlIsVideoId(url: string, videoId: string): boolean {
    const videoUrlRegex = new RegExp(`v=${videoId}(&|$)`)
    return videoUrlRegex.test(url)
}

export function isInRange(value: number, target: number, delta: number) {
    return target - delta <= value && value <= target + delta
}
